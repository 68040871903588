import React, { lazy, Suspense, useRef, useEffect, useState } from 'react'
import { useDeepCompareMemo } from "use-deep-compare";

import {
    dataStore,
    filterStore,
    locationStore,
    mapStore,
    pageStore
} from "state/store-zustand";

import { handlePlaceRoute } from "components/utils/routeUtils"
import { placesToCards } from "components/post/blocks/singCardsBlock/placesToCards"

import SEO from "components/seo"
import Skeleton from "react-loading-skeleton"
import PlaceCards from 'components/elements/cards/placeCards'
import Cards from "components/elements/cards"
import "components/elements/cards/cards.scss"


const Filters = React.lazy(() => import('components/filters/Filters'))
const Map = React.lazy(() => import('components/map'))

const CardLayout = ({
    containerHeight = null,
    expandCollapse = true,
    mapPosition = 'right',
    showCircles = false,
    showLogo = true,
    showMarkers = true,
    showPlaceList = true,
    slug,
    title = 'Explore the map',
    useMobileView,
    zoom,
    ...props
}) => {

    const cardOr = pageStore((state) => state.cardOr)
    const embedded = pageStore((state) => state.embedded)
    const isMobile = pageStore((state) => state.isMobile)
    const isTablet = pageStore((state) => state.isTablet)
    const theme = pageStore((state) => state.theme)
    const showAsMobile = isMobile || useMobileView

    const cardStyle = mapStore((state) => state.cardStyle)
    const mapPos = mapStore((state) => state.mapPos)
    const showAddress = mapStore((state) => state.showAddress)
    const showDescription = mapStore((state) => state.showDescription)
    const showNote = mapStore((state) => state.showNote)
    const zoomCurrent = mapStore((state) => state.zoomCurrent)
    const setZoomPrevious = mapStore((state) => state.setZoomPrevious)

    const hasLocation = locationStore((state) => state.hasLocation)

    const places = dataStore((state) => state.places)
    const placesLoading = dataStore((state) => state.placesLoading)
    const placesLoaded = dataStore((state) => state.placesLoaded)
    const setPlaceCurrent = dataStore((state) => state.setPlaceCurrent)
    const numPlacesToShow = mapStore((state) => state.setPlaceCurrent)
    const setPlacesScrolling = dataStore((state) => state.setPlacesScrolling)

    const showFilters = filterStore((state) => state.showFilters)

    const seoSuffix = ' | ' + title
    const mapRef = useRef()

    const [hasPlace, setHasPlace] = useState(slug ? true : false)
    const [placesLayer, setPlacesLayer] = useState([])
    const schemaData = {}

    // FIXME: Does this render more than it needs too?
    const cards = useDeepCompareMemo(() => placesToCards(places.slice(0, numPlacesToShow), cardStyle), [places, numPlacesToShow])

    const [showLeft, setShowLeft] = useState(false)
    const [showCards, setShowCards] = useState(true)
    const [showMapCards, setShowMapCards] = useState(false)
    const [headerHeight, setHeaderHeight] = useState(`0px`)
    const [panelOffset, setPanelOffset] = useState(`0px`)

    const toggleList = () => {
        setShowLeft(!showLeft)
    }

    const [columnDirection, setColumnDirection] = useState(`row`)
    useEffect(() => {
        setColumnDirection(mapPos == 'left'
            ? 'row-reverse'
            : 'row'
        )
    }, [mapPos])

    useEffect(() => {
        setHeaderHeight(embedded
            ? `0px`
            : isMobile
                ? `90px`
                : `110px`
        )

        setPanelOffset(embedded
            ? `0px`
            : `160px`
        )

    }, [embedded])

    // Handle layout after load
    useEffect(() => {
        const shouldShowCards = cardStyle != 'none'

        const shouldShowLeft = shouldShowCards && !showAsMobile
        setShowCards(shouldShowCards)
        setShowLeft(shouldShowCards)

        // Show left panelon tablet and desktip
        setShowLeft(shouldShowLeft)
        // Show map cards with prop or on mobile
        if (showAsMobile && showPlaceList) setShowMapCards(true)
    }, [showAsMobile, showMapCards, cardStyle])

    // TODO: Make routing changes should happen at the page level, actually
    // Because now that pages have context
    const handleCardClick = (slug, place) => {
        setPlaceCurrent(place)
        setZoomPrevious(zoomCurrent)
        handlePlaceRoute({place: place, location: location, theme: theme})
    }

    const handleCardHover = (event, place) => {
        console.log('handleCardHover ', place);
    }


    const handleScroll = (event) => {
        //console.log('handleScroll in mapLayout ', event);
        setPlacesScrolling(true)
    }

    const onFilterChange = () => { }


    const styleScroll = {
        flexGrow: 1,
        overflow: 'auto',
        minHeight: 0,
    }

    return (
        <div className='card-layout'>
            <SEO
                lang="en-US"
                title={title}
                schemaData={schemaData}
            />
            <div>
                <div>
                    {showFilters
                        ? <Suspense fallback={null}>
                            <Filters
                                //activity={currentActivity}
                                page={'activities'}
                                size={'small'}
                                showActivities={true}
                                showCities={false}
                                showDates={false}
                                showSearch={true}
                                showVibes={true}
                                onChange={onFilterChange} />
                        </Suspense>
                        : null
                    }
                </div>
                <div
                    className='container cards'
                    onScroll={handleScroll}
                    style={styleScroll}>
                    {/* Clones and pass isLoading and places props to cards */}
                    {showCards
                        ? (
                            <PlaceCards
                                listStyle={cardStyle}
                                headerHeight={headerHeight}
                                onClick={handleCardClick}
                                onHover={handleCardHover}
                                showAddress={showAddress}
                                showDescription={showDescription}
                                showNote={showNote}
                                showLoadMore={cardStyle == 'list' ? true : false}>
                                {cardStyle == 'carousel'
                                    ? <Cards
                                        cardStyle={cardStyle}
                                        cardOrientation={cardOr}
                                        columns={3}
                                        size={'base'} />
                                    : <Cards />
                                }
                            </PlaceCards>
                        )
                        : null}
                </div>
            </div>

        </div>
    )
}

export default CardLayout
