import React, { useEffect, useRef, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import dayjs from 'dayjs'

import {
  dataStore,
  defaultValues,
  filterStore,
  locationStore,
  pageStore
} from "state/store-zustand";

import { compareLists } from 'components/map/mapUtils.js'

import { getEventOptions, fetchEvents } from 'vibemap-constants/dist/helpers'
import { eventsToCards } from 'components/post/blocks/singCardsBlock/placesToCards.js'

const FetchEvents = ({
  distance = 60,
  doBroadSearch = false,
  loadMore = true,
  page = 1,
  per_page = 150,
  ...props
}) => {

  const currentLocation = locationStore((state) => state.locationCurrent)
  const cityCurrent = locationStore((state) => state.cityCurrent)
  const hasLocation = locationStore((state) => state.hasLocation)

  const hasFilters = useRef(false)
  const prevFiltersRef = useRef()
  const prevSearch = useRef()
  const prevDateStart = useRef()
  const prevDateEnd = useRef()

  const activitiesCurrent = filterStore((state) => state.activitiesCurrent)
  const currentSearch = filterStore((state) => state.searchCurrent)
  const dateRangeCurrent = filterStore((state) => state.dateRangeCurrent)
  const dateRangeStartEnd = filterStore((state) => state.dateRangeStartEnd)
  const filters = filterStore((state) => state.filters)
  const ordering = filterStore((state) => state.ordering)
  const searchRadius = filterStore((state) => state.searchRadius)
  const dateStart = filterStore((state) => state.dateStart)
  const dateEnd = filterStore((state) => state.dateEnd)
  const tagsCurrent = filterStore((state) => state.tagsCurrent)
  const currentVibes = filterStore((state) => state.vibesCurrent)
  const setTopCategories = filterStore((state) => state.setCategoriesTop)
  const setTagsTop = filterStore((state) => state.setTagsTop)
  const setVibesTop = filterStore((state) => state.setVibesTop)
  const { tags, vibes } = filters

  const boundaries = dataStore((state) => state.boundaries)
  const events = dataStore((state) => state.events)
  const eventCards = dataStore((state) => state.eventCards)
  const eventsLoading = dataStore((state) => state.eventsLoading)
  const eventsLoaded = dataStore((state) => state.eventsLoaded)
  const setNumEvents = dataStore((state) => state.setNumEvents)
  const setEvents = dataStore((state) => state.setEvents)
  const setEventCards = dataStore((state) => state.setEventCards)
  const setEventsLoading = dataStore((state) => state.setEventsLoading)
  const setEventsLoaded = dataStore((state) => state.setEventsLoaded)

  const pageCurrent = pageStore((state) => state.pageCurrent)

  const [firstLoad, setFirstLoad] = useState(true)
  const [expandSearch, setExpandSearch] = useState(doBroadSearch)


  // Main search action, triggered by filter effects
  let abortController = null; // keep track of the current abort controller
  const doEventSearch = (reload = false, page = 1, load_more = false) => {

    if (firstLoad == true) setFirstLoad(false)

    // Cancel previous requests
    if (abortController) {
      abortController.abort();
    }

    // Create a new AbortController for the new request
    abortController = new AbortController();

    // Default queries; override by filter
    let activityQuery = activitiesCurrent
    let cityQuery = cityCurrent ? cityCurrent.slug : undefined
    let dateRangeQuery = dateRangeCurrent ? dateRangeCurrent : filters.dates
    let geoQuery = currentLocation
    let searchQuery = currentSearch ? currentSearch : null
    let tagsQuery = tagsCurrent ? tagsCurrent : null
    let vibeQuery = currentVibes ? currentVibes : null
    // Use user's location, if available

    const hasDistParam = searchRadius && searchRadius !== defaultValues.searchRadius
    const distanceQuery = cityCurrent?.radius && !hasDistParam
      ? parseFloat(cityCurrent.radius)
      : searchRadius
        ? searchRadius
        : distance

    console.log('DEBUG: Search query ', searchQuery, tagsQuery);

    const makeOptions = (geoQuery, activityQuery, searchQuery, vibeQuery, date_range) => {

      console.log('DEBUG: Start and end ', dateRangeCurrent, dateRangeStartEnd);
      const date_range_start = dateStart
        ? dayjs(dateStart)
        : dateRangeStartEnd.start

      const date_range_end = dateEnd
        ? dayjs(dateEnd)
        : dateRangeStartEnd.end

      const options_new = {
        activity: activityQuery,
        category: activityQuery === 'all' ? null : activityQuery,
        distance: distanceQuery,
        per_page: per_page,
        point: `${geoQuery.longitude},${geoQuery.latitude}`,
        ordering: ordering,
        start_date_after: date_range_start,
        end_date_before: date_range_end.format("YYYY-MM-DD HH:mm"),
        search: searchQuery || null,
        shouldShuffle: false,
        shouldSort: false,
        tags: tags,
        vibes: vibeQuery
      }

      return options_new
    }

    // Wait until the correct location data comes as a prop, after first load
    // TODO: What for firstLoad == false?
    if (hasLocation == true) {

      // FIXME: work around for city vs coords
      const options = cityCurrent
        ? getEventOptions(
          cityCurrent,
          dateRangeQuery,
          distanceQuery,
          activityQuery,
          vibeQuery,
          searchQuery,
          tagsQuery,
          dateStart ? dateStart : undefined,
          dateEnd ? dateEnd : undefined,
          page ? page : 1,
          per_page,
          //(filters.vibes.length > 0) ? filters.vibes[0] : actvityParam // TODO: this is a hack to search by activity
        )
        : makeOptions(geoQuery, activityQuery, searchQuery, vibeQuery, dateRangeQuery)


      // Get places based on filters

      const keepResults = loadMore && hasFilters.current == false  // FIXME: consolidate this
      if (eventsLoading == false && keepResults == false) setEventsLoading(true)

      fetchEvents(options)
        .then(response => {
          const eventsData = response?.data
          const newEvents = eventsData?.results?.features
            ? eventsData.results.features
            : []

          // Set stats
          // FIXME: this is incorrect in the API, hence the hack
          const numEvents = newEvents.length
          setNumEvents(numEvents || response?.count)

          const eventCardsNew = eventsToCards(newEvents)
          const allCards = keepResults
            ? eventCards.concat(eventCardsNew)
            : eventCardsNew

          // Remove duplicates
          // TODO: move this to the backend or shared component
          const cardsUnique = allCards.filter((card, index, self) => {
            // hash name + start date
            const name_date = card?.properties?.name + ' ' + card?.properties?.start_date
            return index === self.findIndex((c) => (
              c?.properties?.name + ' ' + c?.properties?.start_date === card?.properties?.name + ' ' + card?.properties?.start_date
            ))
          }).sort((a, b) => {
            return new Date(a.properties.start_date) - new Date(b.properties.start_date)
          })

          // Only update cards if they've changed
          const isSame = compareLists(eventCards, cardsUnique)
          if (!isSame) {
            setEventCards(cardsUnique)
            setEvents(newEvents)
            setNumEvents(numEvents)
          }

          // Only set to on the events page
          if (pageCurrent.includes('events')) {
            setTopCategories(response?.top_categories)
            setTagsTop(response?.top_tags)
            setVibesTop(response?.top_vibes)
          }
          setEventsLoading(false)
          setEventsLoaded(true)
        })
    }
  }

  const numEvents = events?.length
  const hasNotLoaded = !eventsLoaded
  const hasResults = eventsLoaded || numEvents > 0
  // FIXME: This is more of a shouldSearch or hasParams flag
  const hasActivities = activitiesCurrent && activitiesCurrent.length > 0
  const hasTagsFilter = tagsCurrent && tagsCurrent.length > 0
  const hasVibes = currentVibes && currentVibes.length > 0
  hasFilters.current = hasActivities || hasTagsFilter || hasVibes || currentSearch != null
  const hasParams = !hasResults || hasFilters.current || page != 1

  const debouncedSearch = useDebouncedCallback(
    // function
    (reload = false, value = null) => {
      try {
        doEventSearch(reload)
      } catch (e) {
        console.log('DEBUG: Error in doEventSearch ', e);
      }
    },
    800, // delay in ms
    { leading: false }
  )

  // Hydrate places and events data on page load
  const filtersDep = JSON.stringify(filters)
  useEffect(() => {
    (async () => {
      // Don't fetch if cards are already present
      // And fitlers haven't changed
      // If no result, selectedCity is undefined, which works for the query options
      //debouncedSearch()
    })()
  }, [])

  // Full Search when filters change
  const activitiesCurrentDep = JSON.stringify(activitiesCurrent)
  const currentLocationDep = JSON.stringify(currentLocation)
  const currentVibesDep = JSON.stringify(currentVibes)
  const tagsDep = JSON.stringify(tags)
  const dateStartDep = JSON.stringify(dateStart)
  const dateEndDep = JSON.stringify(dateEnd)

  useEffect(() => {
    (async () => {
      const dateChanged = dateStartDep != prevDateStart.current || dateEndDep != prevDateEnd.current;
      const searchChanged = currentSearch != prevSearch.current;
      const prevFilters = prevFiltersRef.current;
      const filtersChanged = filtersDep != JSON.stringify(prevFilters) || dateChanged || page != 1 || searchChanged

      const shouldSearch = !eventsLoading && hasLocation && (hasParams || filtersChanged)
      //console.log('DEBUG: dateStart ', dateStart);
      //console.log('DEBUG: shouldSearch ', shouldSearch, dateChanged, dateEndDep);

      if (shouldSearch) {
        debouncedSearch()

        // Update refs
        prevDateStart.current = dateStartDep;
        prevDateEnd.current = dateEndDep;
        prevFiltersRef.current = filters;
        prevSearch.current = currentSearch;
      }
    })()
  }, [
    searchRadius,
    expandSearch,
    filtersDep,
    hasParams,
    dateStartDep,
    dateEndDep,
    activitiesCurrentDep,
    hasLocation,
    currentLocationDep,
    currentSearch,
    currentVibesDep,
    tagsDep,
    page
  ])

  return null
}

//export default FetchEvents
export default React.memo(FetchEvents)